export default function (instance) {
  return {
    get_users() {
      return instance.post("get_users", {});
    },
    add_users(p) {
      return instance.post("add_users", p);
    },
  };
}
