import instance from "./instance";

import authModule from "./modules/auth";
import usersModule from "./modules/users";
import acModule from "./modules/ac";
import sostavModule from "./modules/sostav";
import shailooModule from "./modules/shailoo";
import kandModule from "./modules/kand";
import othersModule from "./modules/others";

export default {
  instance,
  auth: authModule(instance),
  users: usersModule(instance),
  ac: acModule(instance),
  sostav: sostavModule(instance),
  shailoo: shailooModule(instance),
  kand: kandModule(instance),
  others: othersModule(instance),
};
