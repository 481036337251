<template>
  <div class="side-nav">
    <div class="side-nav-inner">
      <ul class="side-nav-menu scrollable">
        <router-link class="nav-item" :to="{ name: 'Users' }" tag="li" exact>
          <a href="#">
            <span class="icon-holder">
              <i class="fas fa-user-friends"></i>
            </span>
            <span class="title">Колдонуучулар</span>
          </a>
        </router-link>

        <router-link class="nav-item" :to="{ name: 'Sostav' }" tag="li" exact>
          <a href="#">
            <span class="icon-holder">
              <i class="fas fa-users"></i>
            </span>
            <span class="title">Окумуштуулар кеңеши</span>
          </a>
        </router-link>

        <router-link class="nav-item" :to="{ name: 'Vote' }" tag="li" exact>
          <a href="#">
            <span class="icon-holder">
              <i class="fas fa-vote-yea"></i>
            </span>
            <span class="title">Шайлоолор</span>
          </a>
        </router-link>

        <router-link class="nav-item" :to="{ name: 'Aspt' }" tag="li" exact>
          <a href="#">
            <span class="icon-holder">
              <i class="fas fa-user-check"></i>
            </span>
            <span class="title">Катышуучулар</span>
          </a>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminSide",
};
</script>
<style lang="scss" scoped></style>
