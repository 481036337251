<template>
  <div class="main-content">
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2 class="header-title">Шайлоо</h2>
        </div>
      </div>
    </div>
    <!-- Content goes Here -->
    <div class="card shadow-lg">
      <div class="card-body">
        <h3 class="shTema text-center">{{ shailoo.name }}</h3>
        <hr />
        <div class="countBlock">
          <div class="item">
            <div class="name">Бюллетень</div>
            <div class="count">{{ shailoo.bulleten_count }}</div>
          </div>
          <div class="item">
            <div class="name">Шайлаган</div>
            <div class="count">{{ shailoo.golos_count }}</div>
          </div>
        </div>
        <hr />
        <div class="shTema2">ЖЫЙЫНТЫГЫ</div>
        <div class="result">
          <div class="item" v-for="item in shailoo.kondidaty" :key="item.id">
            <div class="name">{{ item.fio }}</div>
            <div class="count">{{ item.golos }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center my-3">
      <button type="button" class="btn btn-secondary" @click="goBack()">
        Артка
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Result",
  components: {},
  data() {
    return {
      idShailoo: null,
      shailoo: {},
    };
  },
  computed: {},
  methods: {
    async info_shailoo() {
      try {
        const data = (
          await this.$api.others.info_shailoo({ id_shailoo: this.idShailoo })
        ).data;
        this.shailoo = data;
        console.log(data);
      } catch (error) {
        if (error.response.status == 401) {
          this.$router.push("/");
        }
      }
    },
    goBack() {
      this.$router.push({ name: "Vote" });
    },
  },
  created() {
    this.idShailoo = this.$route.params.id;
  },

  mounted() {
    this.$nextTick(function () {
      this.info_shailoo();
    });
  },
};
</script>
<style lang="scss" scoped>
.main-content {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.shTema {
  color: rgb(18, 60, 124);
}
.shTema2 {
  color: rgb(48, 84, 138);
  font-size: 1.4em;
  text-align: center;
  margin: 20px;
  font-weight: 500;
}
.countBlock {
  .item {
    width: 250px;
    margin: auto;
    display: flex;
    font-size: 2em;
    font-weight: 500;
    color: #814cc7;
    justify-content: space-between;
    .name {
      margin-right: 20px;
    }
  }
}
.result {
  .item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
    font-size: 1.2em;
    font-weight: 500;
    &:last-child {
      border-bottom: none;
    }
    .name {
      padding: 10px 0;
    }
    .count {
      padding: 10px 0;
    }
  }
}
</style>
