<template>
  <nav class="d-flex justify-content-center" v-if="total > 0">
    <ul class="pagination">
      <li
        class="page-item"
        :class="{ disabled: activePage == 1 }"
        @click.prevent="prew"
      >
        <a class="page-link" href="#">
          <i class="anticon anticon-double-left"></i>
        </a>
      </li>

      <li
        class="page-item"
        v-for="index in allNumber"
        :key="index"
        @click.prevent="slectPage(index)"
        :class="{ active: activePage == index }"
      >
        <a class="page-link" href="#">
          {{ index }}
        </a>
      </li>

      <li
        class="page-item"
        :class="{ disabled: activePage == allNumber }"
        @click.prevent="next"
      >
        <a class="page-link" href="#">
          <i class="anticon anticon-double-right"></i>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    total: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    perPage: {
      type: Number,
      default: () => {
        return 10;
      },
    },
  },
  data() {
    return {
      activePage: 1,
    };
  },
  computed: {
    allNumber() {
      return Math.ceil(this.total / this.perPage);
    },
  },
  watch: {
    total() {
      if (this.activePage > this.allNumber) {
        this.activePage = 1;
      }
    },
    perPage() {
      if (this.activePage > this.allNumber) this.activePage = this.allNumber;
      this.$emit("activePage", this.activePage);
    },
    activePage() {
      this.$emit("activePage", this.activePage);
    },
  },
  methods: {
    slectPage(i) {
      this.activePage = i;
    },
    next() {
      if (this.activePage < this.allNumber) {
        this.activePage++;
      }
    },
    prew() {
      if (this.activePage > 1) {
        this.activePage--;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
