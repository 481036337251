<template>
  <div>
    <div class="row align-items-end">
      <div class="col-auto flex-grow-1">
        <div class="form-group">
          <label for="select_1_1">Аталышы <span>*</span></label>
          <select
            class="form-control"
            id="select_1_1"
            v-model="id_ac"
            @change="acSelect"
            required
          >
            <option value="0" selected disabled>-- выбрать --</option>
            <template v-if="acList.length">
              <option v-for="item in acList" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </template>
          </select>
        </div>
      </div>

      <div class="col-auto">
        <button
          class="btn btn-primary mb-4 ml-auto d-block"
          style="white-space: nowrap"
          @click="addModalShow"
          :disabled="!id_ac"
        >
          Кошуу
        </button>
      </div>
    </div>
    <hr class="mt-0 mb-2" />
    <template v-if="newSearchList.length">
      <div class="row align-items-center" v-if="newSearchList.length">
        <div class="col-auto mr-auto mb-2">
          <PerPageLenth
            class="mr-3"
            :page="page"
            :perPage="perPage"
            :allDataLenth="newSearchList.length"
          />
          <PerPageCount :perPage="perPage" @perPageSet="perPageSet" />
        </div>
        <div class="col-auto ml-auto mb-2">
          <div class="input-affix">
            <i class="prefix-icon anticon anticon-search"></i>
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="searchText"
              placeholder="Поиск"
            />
          </div>
        </div>
      </div>
      <hr class="mt-0 mb-2" />
    </template>
    <template v-if="newSearchList.length">
      <table
        class="table table-sm table-responsive-md table-striped table-hover"
      >
        <thead class="">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Аталышы</th>
            <th scope="col">Маалымат</th>
            <th scope="col">Катталган</th>
            <th scope="col" class="text-center">Катышуу</th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in newDatalist" :key="item.id">
            <th scope="row">{{ index + perPage * (page - 1) + 1 }}</th>
            <td>{{ item.fio }}</td>
            <td>{{ item.info }}</td>
            <td>{{ new Date(item.created_at).toLocaleString() }}</td>
            <td class="text-center">
              <div class="checkbox">
                <input
                  :id="'id' + index"
                  type="checkbox"
                  v-model="item.act"
                  @change="actChange(item)"
                />
                <label class="tizme" :for="'id' + index"></label>
              </div>

              <!-- <div class="switch">
                  <input type="checkbox" :id="'id'+index" >
                  <label :for="'id'+index"></label>
                </div> -->
            </td>
            <td class="text-right">
              <!-- <button class="btn btn-primary btn-xs mr-1">btn-xs</button> -->
              <!-- <button class="btn btn-icon btn-sm btn-success btn-tone mr-1 mb-1 mb-lg-0"
                  @click="editModalShow(item)"
                >
                  <i class="anticon anticon-edit"></i>
                </button> -->
              <button
                class="btn btn-icon btn-sm btn-danger btn-tone"
                @click="deleteModalShow(item)"
              >
                <i class="anticon anticon-delete"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
    </template>
    <div v-else class="noTdData">Маалымат жок</div>
    <Pagination
      :total="newSearchList.length"
      :perPage="perPage"
      @activePage="currentPage"
    />
    <Modal :isShow="isAddModalShow" @closeModal="addModalClose">
      <template v-slot:title> Кошуу </template>
      <template>
        <div class="form-group">
          <label for="select_1_1">Тизме <span>*</span></label>
          <select
            class="form-control"
            id="select_1_1"
            v-model="sostav.id_user"
            required
          >
            <option value="0" selected disabled>-- выбрать --</option>
            <template v-if="userlist.length">
              <option v-for="item in userlist" :key="item.id" :value="item.id">
                {{ item.f + " " + item.i + " " + item.o }}
              </option>
            </template>
          </select>
        </div>
        <div class="form-group">
          <label for="in1">Маалымат</label>
          <input
            type="text"
            class="form-control"
            id="in1"
            v-model="sostav.info"
            required
          />
        </div>
      </template>

      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-danger mr-3"
          @click="addModalClose"
        >
          Артка
        </button>
        <button type="button" class="btn btn-primary" @click="addPost">
          Сактоо
        </button>
      </template>
    </Modal>
    <Modal :isShow="isEditModalShow" @closeModal="editModalClose">
      <template v-slot:title> Өзгөртүү </template>
      <template v-if="newDatalist.length"> </template>

      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-danger mr-3"
          @click="editModalClose"
        >
          Артка
        </button>
        <button type="button" class="btn btn-primary" @click="editPost">
          Сактоо
        </button>
      </template>
    </Modal>
    <Modal :isShow="isDeleteModalShow" @closeModal="deleteModalClose">
      <template v-slot:title> Өчүрүү </template>

      <template>
        <h5>Чындап эле өчүрүүнү каалайсызбы?</h5>
        <p>{{ sostav.fio }}</p>
      </template>

      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-danger mr-3"
          @click="deleteModalClose"
        >
          Артка
        </button>
        <button type="button" class="btn btn-primary" @click.prevent="delPost">
          Тастыктоо
        </button>
      </template>
    </Modal>
  </div>
</template>
<script>
import Pagination from "@/components/others/Pagination.vue";
import PerPageLenth from "@/components/others/PerPageLenth.vue";
import PerPageCount from "@/components/others/PerPageCount.vue";
import Modal from "@/components/others/Modal.vue";

export default {
  name: "SSostav",
  components: {
    Pagination,
    PerPageLenth,
    PerPageCount,
    Modal,
  },
  data() {
    return {
      isAddModalShow: false,
      isEditModalShow: false,
      isDeleteModalShow: false,

      page: 1,
      perPage: 10,
      searchText: "",
      userlist: [],
      acList: [],
      id_ac: null,
      sostav: {
        id_user: "",
        id_ac: "",
        fio: "",
        info: "",
        act: false,
        created_at: null,
      },
      sostavList: [],
    };
  },
  computed: {
    newSearchList() {
      const regEx = new RegExp(this.searchText, "i");
      return this.sostavList.filter((x) => x.fio.match(regEx));
    },
    newDatalist() {
      return this.newSearchList.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
  },
  methods: {
    currentPage(i) {
      this.page = i;
    },
    perPageSet(count) {
      this.perPage = count;
    },

    addModalShow() {
      this.sostav.id_ac = this.id_ac;
      this.get_users();
      this.isAddModalShow = true;
    },
    addModalClose() {
      this.sostav = {};
      this.isAddModalShow = false;
    },
    editModalShow(item) {
      this.sostav = { ...item };
      this.isEditModalShow = true;
    },
    editModalClose() {
      this.sostav = {};
      this.isEditModalShow = false;
    },
    deleteModalShow(item) {
      this.sostav = { ...item };
      this.isDeleteModalShow = true;
    },
    deleteModalClose() {
      this.sostav = {};
      this.isDeleteModalShow = false;
    },
    acSelect() {
      this.get_sostav();
    },

    async get_users() {
      try {
        const data = (await this.$api.users.get_users()).data;
        this.userlist = data;
      } catch (error) {
        if (error.response.status == 401) {
          this.$router.push("/");
        }
      }
    },
    async get_ac() {
      try {
        const data = (await this.$api.ac.get_ac()).data;
        this.acList = data;
      } catch (error) {
        if (error.response.status == 401) {
          this.$router.push("/");
        }
      }
    },
    async get_sostav() {
      try {
        const data = (await this.$api.sostav.get_sostav({ id_ac: this.id_ac }))
          .data;
        this.sostavList = data;
      } catch (error) {
        if (error.response.status == 401) {
          this.$router.push("/");
        }
      }
    },
    async add_sostav() {
      try {
        await this.$api.sostav.add_sostav(this.sostav);
        this.$nt("Успешно сохранен", "success");
        this.get_sostav();
      } catch (error) {
        if (error.response.status == 409) {
          console.log("409");
        }
        this.$nt("Ошибка сохранения", "dagner");
      }
    },
    async delete_sostav() {
      try {
        await this.$api.sostav.delete_sostav(this.sostav);
        this.$nt("Успешно удален", "success");
        this.get_sostav();
      } catch (error) {
        if (error.response.status == 409) {
          console.log("409");
        }
        this.$nt("Ошибка удаления", "dagner");
      }
    },
    async act_sostav_ac(item) {
      try {
        await this.$api.others.act_sostav_ac({
          id: item.id,
          act: item.act,
        });
      } catch (error) {
        console.log(error);
      }
    },

    addPost() {
      this.add_sostav();
      this.addModalClose();
    },
    editPost() {
      // this.update_ac()
      // this.editModalClose()
    },
    delPost() {
      this.delete_sostav();
      this.deleteModalClose();
    },
    actChange(item) {
      this.act_sostav_ac(item);
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.get_ac();
    });
  },
};
</script>
<style lang="scss" scoped>
td:last-child {
  word-wrap: none;
}
.checkbox input[type="checkbox"] + label.tizme:before {
  margin-right: 0;
  border: 2px solid #9dc2f5;
}
.tizme {
  margin-bottom: 0;
}
.switch input[type="checkbox"] + label {
  transition: 0.2s ease;
  height: 20px;
  width: 40px;
  border-radius: 30px;
}
.switch input[type="checkbox"] + label:before {
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 20px;
  width: 40px;
  border-radius: 30px;
}

.switch input[type="checkbox"] + label:after {
  transition: 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  height: 16px;
  width: 16px;
  border-radius: 60px;
}
.switch input[type="checkbox"]:checked + label:after {
  left: 21px;
}
</style>
