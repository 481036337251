<template>
  <div class="main-content">
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2 class="header-title">Окумуштуулар кеңеши</h2>
        </div>
        <!-- <div class="col-auto ml-auto">
          <button class="btn btn-primary" style="white-space: nowrap;" @click="addModalShow">Добавить</button>
        </div> -->
      </div>
    </div>
    <!-- Content goes Here -->
    <div class="card">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" v-for="(item, index) in tabs" :key="index">
          <a
            class="nav-link"
            href="#"
            :class="{ active: index == tab_active }"
            @click.prevent="tab_active = index"
          >
            {{ item.name_ru }}
          </a>
        </li>
      </ul>
      <div class="card-body">
        <div class="tab_block" v-if="tab_active == 0">
          <SName />
        </div>
        <div class="tab_block" v-if="tab_active == 1">
          <SSostav />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SName from "@/components/sostav/SName.vue";
import SSostav from "@/components/sostav/SSostav.vue";

export default {
  name: "Sostav",
  components: {
    SName,
    SSostav,
  },
  data() {
    return {
      tabs: [{ name_ru: "Атылышы" }, { name_ru: "Тизме" }],
      tab_active: 0,
    };
  },
  computed: {},
  methods: {},
  mounted() {
    this.$nextTick(function () {});
  },
};
</script>
<style lang="scss" scoped>
.card {
  overflow: hidden;
}
.nav-tabs > li > a {
  font-weight: 500;
}
.nav-tabs > li > a.active.nav-link {
  border-bottom: none;
  color: #fff;
  background-color: #886cff;
}
.nav-tabs {
  border-bottom: 1px solid #b6a6ff;
}
</style>
