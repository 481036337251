export default function (instance) {
  return {
    get_ac() {
      return instance.post("get_ac", {});
    },
    add_ac(p) {
      return instance.post("add_ac", p);
    },
    update_ac(p) {
      return instance.post("update_ac", p);
    },
    delete_ac(p) {
      return instance.post("delete_ac", p);
    },
  };
}
