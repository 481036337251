<template>
  <div class="main-content">
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2 class="header-title">Катышуучулар</h2>
        </div>
      </div>
    </div>
    <!-- Content goes Here -->
    <div class="card con">
      <div class="card-body">
        <div class="row align-items-end">
          <div class="col-auto flex-grow-1">
            <div class="form-group">
              <label for="select_1_1">Шайлоолор <span>*</span></label>
              <select
                class="form-control"
                id="select_1_1"
                v-model="shailoo_id"
                @change="shailooSelect"
                required
              >
                <option value="0" selected disabled>-- выбрать --</option>
                <template v-if="shailooList.length">
                  <option
                    v-for="item in shailooList"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </template>
              </select>
            </div>
          </div>

          <div class="col-auto">
            <button
              class="btn btn-primary mb-4 ml-auto d-block"
              style="white-space: nowrap"
              @click="addModalShow"
              :disabled="!shailoo_id"
            >
              Кошуу
            </button>
          </div>
        </div>
        <template v-if="newSearchList.length">
          <div class="row align-items-center" v-if="newSearchList.length">
            <div class="col-auto mr-auto mb-2">
              <PerPageLenth
                class="mr-3"
                :page="page"
                :perPage="perPage"
                :allDataLenth="newSearchList.length"
              />
              <PerPageCount :perPage="perPage" @perPageSet="perPageSet" />
            </div>
            <div class="col-auto ml-auto mb-2">
              <div class="input-affix">
                <i class="prefix-icon anticon anticon-search"></i>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="searchText"
                  placeholder="Поиск"
                />
              </div>
            </div>
          </div>
          <hr class="mt-0 mb-2" />
        </template>
        <template v-if="newSearchList.length">
          <table class="table table-responsive-md table-striped table-hover">
            <thead class="">
              <tr>
                <th scope="col">#</th>
                <th scope="col">ФАА</th>
                <th scope="col">Маалымат</th>
                <th scope="col">Катталган</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in newDatalist" :key="item.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ item.fio }}</td>
                <td>{{ item.info }}</td>

                <td>{{ new Date(item.created_at).toLocaleString() }}</td>

                <td class="text-right">
                  <button
                    class="btn btn-icon btn-sm btn-danger btn-tone"
                    @click="deleteModalShow(item)"
                  >
                    <i class="anticon anticon-delete"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
        </template>
        <div v-else class="noTdData">Маалымат жок</div>
        <Pagination
          :total="newSearchList.length"
          :perPage="perPage"
          @activePage="currentPage"
        />
      </div>
    </div>
    <Modal :isShow="isAddModalShow" @closeModal="addModalClose">
      <template v-slot:title> Катышуучуну кошуу </template>
      <template>
        <div class="form-group">
          <label for="in1">ФАА</label>
          <input
            type="text"
            class="form-control"
            id="in1"
            v-model="condidate.fio"
            required
          />
        </div>
        <div class="form-group">
          <label for="in2">Маалымат</label>
          <input
            type="text"
            class="form-control"
            id="in2"
            v-model="condidate.info"
            required
          />
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-danger mr-3"
          @click="addModalClose"
        >
          Артка
        </button>
        <button type="button" class="btn btn-primary" @click="addPost">
          Сактоо
        </button>
      </template>
    </Modal>
    <Modal :isShow="isDeleteModalShow" @closeModal="deleteModalClose">
      <template v-slot:title> Кандидатты өчүрүү </template>

      <template>
        <h5>Чындап эле өчүрүүнү каалайсызбы?</h5>
        <p>{{ condidate.fio }}</p>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-danger mr-3"
          @click="deleteModalClose"
        >
          Артка
        </button>
        <button type="button" class="btn btn-primary" @click="delPost">
          Тастыктоо
        </button>
      </template>
    </Modal>
  </div>
</template>
<script>
import Pagination from "@/components/others/Pagination.vue";
import PerPageLenth from "@/components/others/PerPageLenth.vue";
import PerPageCount from "@/components/others/PerPageCount.vue";
import Modal from "@/components/others/Modal.vue";

export default {
  name: "Aspt",
  components: {
    Pagination,
    PerPageLenth,
    PerPageCount,
    Modal,
  },
  data() {
    return {
      isAddModalShow: false,
      isEditModalShow: false,
      isDeleteModalShow: false,

      page: 1,
      perPage: 10,
      searchText: "",

      shailoo_id: null,
      shailooList: [],
      condidateList: [],
      condidate: {
        id: null,
        id_shailoo: null,
        fio: "",
        info: "",
        created_at: null,
      },
    };
  },
  created() {
    // this.list = fakec
  },
  computed: {
    newSearchList() {
      const regEx = new RegExp(this.searchText, "i");
      return this.condidateList.filter((x) => x.fio.match(regEx));
    },
    newDatalist() {
      return this.newSearchList.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
  },
  methods: {
    currentPage(i) {
      this.page = i;
    },
    perPageSet(count) {
      this.perPage = count;
    },
    addModalShow() {
      this.condidate.id_shailoo = this.shailoo_id;
      this.isAddModalShow = true;
    },
    addModalClose() {
      this.condidate = {};
      this.isAddModalShow = false;
    },
    editModalShow(item) {
      this.condidate = { ...item };
      this.isEditModalShow = true;
    },
    editModalClose() {
      this.condidate = {};
      this.isEditModalShow = false;
    },
    deleteModalShow(item) {
      this.condidate = { ...item };
      this.isDeleteModalShow = true;
    },
    deleteModalClose() {
      this.condidate = {};
      this.isDeleteModalShow = false;
    },
    shailooSelect() {
      this.get_kand();
    },
    async get_shailoo() {
      try {
        const data = (await this.$api.shailoo.get_shailoo()).data;
        this.shailooList = data.reverse();
      } catch (error) {
        if (error.response.status == 401) {
          this.$router.push("/");
        }
      }
    },
    async get_kand() {
      try {
        const data = (
          await this.$api.kand.get_kand({
            id_shailoo: this.shailoo_id,
          })
        ).data;
        this.condidateList = data;
      } catch (error) {
        if (error.response.status == 401) {
          this.$router.push("/");
        }
      }
    },
    async add_kand() {
      try {
        await this.$api.kand.add_kand(this.condidate);
        this.$nt("Успешно сохранен", "success");
        this.get_kand();
      } catch (error) {
        if (error.response.status == 409) {
          console.log("409");
        }
        this.$nt("Ошибка сохранения", "dagner");
      }
    },
    async delete_kand() {
      try {
        await this.$api.kand.delete_kand(this.condidate);
        this.$nt("Успешно удален", "success");
        this.get_kand();
      } catch (error) {
        if (error.response.status == 409) {
          console.log("409");
        }
        this.$nt("Ошибка удаления", "dagner");
      }
    },
    addPost() {
      this.add_kand();
      this.addModalClose();
    },
    delPost() {
      this.delete_kand();
      this.deleteModalClose();
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.get_shailoo();
    });
  },
};
</script>

<style lang="scss" scoped></style>
