<template>
  <div class="notification">
    <transition-group name="notificate-animate" class="m-list" tag="div">
      <div
        class="n-item"
        v-for="(message, index) in getMSG"
        :key="message.id"
        :class="message.status"
        @click="clickN(index)"
      >
        <div class="n-item__text">
          {{ message.name }}
        </div>
        <div class="n-item__icon">
          <i
            class="anticon"
            :class="'anticon-' + statusCheck(message.status)"
          ></i>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Notification",

  data() {
    return {
      count: 0,
    };
  },
  computed: {
    ...mapGetters({
      getMSG: "notificate/getMSG",
    }),
    countT() {
      return this.$store.state.notificate.messages.length;
    },
  },
  methods: {
    ...mapActions({
      deleteMSG: "notificate/deleteMSG",
    }),
    statusCheck(t) {
      switch (t) {
        case "success":
          return "check-circle";
        case "warning":
          return "warning";
        case "danger":
          return "info-circle";
        default:
          return "info-circle";
      }
    },
    clickN() {
      // this.messages.splice(i,1)
    },
  },
  watch: {
    countT() {
      if (this.countT > this.count) this.deleteMSG();
      this.count = this.countT;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.notification {
  position: fixed;
  top: 6.5em;
  right: 1.7em;
  // display: flex;
  //   flex-direction: column-reverse;
  .m-list {
    display: flex;
    flex-direction: column-reverse;
  }
  .n-item {
    width: 300px;
    padding: 0.5em 1em;
    overflow: hidden;
    height: auto;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 1em;
    background-color: #bd4545;
    border: 1px solid #ffffff;
    outline: 2px solid #bd4545;
    &__icon {
      font-size: 1.5em;
    }
    &.success {
      background-color: #45bd4c;
      outline-color: #45bd4c;
    }
    &.danger {
      background-color: #bd4545;
      outline-color: #bd4545;
    }
    &.warning {
      background-color: #bdac45;
      outline-color: #bdac45;
    }
  }
}
.notificate-animate {
  display: flex;
  flex-direction: column-reverse;
  &-enter {
    transform: translateX(100px);
    opacity: 0;
  }
  &-enter-active {
    // transition-timing-function: cubic-bezier(0.475, 0.545, 0.255, 1.250);
    transition: transform 0.3s cubic-bezier(0.44, 0.21, 0.295, 1.32),
      opacity 0.3s ease;
  }
  &-enter-to {
    opacity: 1;
  }
  &-leave {
    opacity: 1;
    height: 50px;
    // position: absolute;
  }
  &-leave-active {
    transition: all 0.3s;
  }
  &-leave-to {
    transform: translateX(100px);
    opacity: 0;
    height: 0px;
  }
  &-move {
    transition: transform 0.3s ease;
  }
}
</style>
