<template>
  <div class="header">
    <div class="logo logo-dark">
      <a href="index.html">
        <!-- <img src="assets/images/logo/logo.png" alt="Logo">
        <img class="logo-fold" src="assets/images/logo/logo-fold.png" alt="Logo"> -->
        <img src="@/assets/images/logo/osu.svg" alt="Logo" />
        <img class="logo-fold" src="@/assets/images/logo/osu.svg" alt="Logo" />
      </a>
    </div>
    <div class="logo logo-white">
      <a href="index.html">
        <img src="@/assets/images/logo/osu.svg" alt="Logo" />
        <img class="logo-fold" src="@/assets/images/logo/osu.svg" alt="Logo" />
      </a>
    </div>
    <div class="nav-wrap">
      <ul class="nav-left">
        <li class="desktop-toggle">
          <a href="#" @click.prevent="isFoldOn">
            <i class="anticon"></i>
          </a>
        </li>
        <li class="mobile-toggle">
          <a href="#">
            <i class="anticon"></i>
          </a>
        </li>
      </ul>
      <ul class="nav-right">
        <li class="dropdown dropdown-animated scale-left" @click="showProfile">
          <div class="pointer" data-toggle="dropdown">
            <div class="avatar avatar-image bg-primary m-h-10 m-r-15">
              <!-- <img src=""  alt=""> -->
              <i class="fas fa-user-alt"></i>
            </div>
          </div>
          <div
            class="p-b-15 p-t-20 dropdown-menu pop-profile"
            :class="{ show: isProfile }"
          >
            <div class="p-h-20 p-b-15 m-b-10 border-bottom">
              <div class="d-flex">
                <div class="avatar avatar-lg avatar-image bg-primary">
                  <!-- <img src="" alt=""> -->
                  <i class="fas fa-user-alt"></i>
                </div>
                <div class="m-l-10">
                  <p class="m-b-0 text-dark font-weight-semibold">
                    {{ this.getUser.name }}
                  </p>
                  <p class="m-b-0 opacity-07">Администратор</p>
                </div>
              </div>
            </div>

            <a
              href="#"
              class="dropdown-item d-block p-h-15 p-v-10"
              @click.prevent="logOut"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <i class="anticon opacity-04 font-size-16 anticon-logout"></i>
                  <span class="m-l-10">Выход</span>
                </div>
                <i class="anticon font-size-10 anticon-right"></i>
              </div>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AdminHeader",
  data() {
    return {
      isFold: false,
      isProfile: false,
      //   userName : this.getUser.name
    };
  },
  computed: {
    ...mapGetters({
      ISFOLDED: "ISFOLDED",
      getUser: "user/getUser",
    }),
  },
  methods: {
    ...mapActions({
      ISFOLDED_CHANGE: "ISFOLDED_CHANGE",
      deleteUser: "user/deleteUser",
    }),

    isFoldOn() {
      console.log("asdasdasd");
      this.isFold = !this.isFold;
      this.ISFOLDED_CHANGE(this.isFold);
    },
    showProfile() {
      this.isProfile = !this.isProfile;
    },
    logOut() {
      this.deleteUser();
      this.$api.instance.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("user");
      this.$router.push("/");
    },
  },
};
</script>
<style lang=""></style>
