export default {
  install(Vue) {
    Vue.prototype.$nt = function (msg, status) {
      this.$store.dispatch("notificate/setMSG", {
        id: Date.now().toString() + Math.floor(Math.random() * 1000).toString(),
        name: msg,
        status: status,
      });
    };
  },
};
