export default function (instance) {
  return {
    get_sostav(p) {
      return instance.post("get_sostav_ac", p);
    },
    add_sostav(p) {
      return instance.post("add_sostav_ac", p);
    },
    delete_sostav(p) {
      return instance.post("delete_sostav_ac", p);
    },
  };
}
