<template>
  <div class="bulletin">
    <div class="bulletin__body d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <div class="card shadow-lg">
              <div class="card-body">
                <img class="logo" alt="" src="@/assets/images/logo/osu.svg" />
                <template v-if="isBulleten">
                  <h3 class="text-center px-3">{{ bulleten.name }}</h3>
                  <h4 class="text-center px-3">{{ bulleten.doljnost }}</h4>
                  <p class="text-center px-3">
                    {{ new Date(bulleten.vrem).toLocaleString() }}
                  </p>

                  <div
                    class="item"
                    :class="{ active: item.status }"
                    v-for="item in kondidaty"
                    :key="item.id"
                  >
                    <div class="info">
                      <div class="name">
                        {{ item.fio }}
                      </div>
                      <div class="des">{{ item.info }}</div>
                    </div>
                    <label class="check" :for="'id' + item.id"></label>
                    <input
                      :id="'id' + item.id"
                      type="checkbox"
                      v-model="item.status"
                      @change="checkClick(item.status)"
                      :disabled="isAllHide && !item.status"
                    />
                  </div>
                </template>
                <div v-else>
                  <h3 class="waiting">
                    Азырынча добуш берүү баштала элек, күтө туруңуз
                  </h3>
                </div>
              </div>
            </div>
            <div class="text-center my-5 footer_btn" v-if="isBulleten">
              <button
                type="button"
                class="btn btn-success mr-3"
                @click="voteGo()"
              >
                Добуш берүү
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer START -->
    <footer class="footer">
      <div class="footer-content">
        <p class="m-b-0 mx-auto text-center">
          Информация© 2021 ОшГУ | Development © 2022 OshSU - IT Academy
        </p>
        <span @click="toAdmin">Admin</span>
      </div>
    </footer>
    <!-- Footer END -->
  </div>
</template>
<script>
export default {
  name: "Bulletin",
  components: {},
  // watch: {
  //   kondidaty(){
  //     this.status = this.kondidaty.map(i=> {return i.status})
  //   }
  // },
  data() {
    return {
      isBulleten: false,
      bulleten: {
        id_bulleten: null,
        id_shailoo: null,
        kondidaty: [
          {
            status: false,
          },
        ],
        m_v: 0,
      },
      kondidaty: [],
      m_v: 0,
      checkCount: 0,
      isAllHide: false,
      idKandidates: [],
      stt: false,
    };
  },
  methods: {
    toAdmin() {
      this.$router.push({ name: "Users" });
    },
    async get_bulleten() {
      try {
        const data = (await this.$api.others.get_bulleten()).data;
        if (data == "isbulleten") {
          this.isBulleten = false;
          const vm = this;
          const st = setTimeout(() => {
            vm.get_bulleten();
          }, 5000);
          if (this.stt) clearTimeout(st);
        } else {
          this.bulleten = data;
          this.kondidaty = [...data.kondidaty];
          this.m_v = data.m_v;
          this.isBulleten = true;
        }

        console.log(data);
      } catch (error) {
        if (error.response.status == 401) {
          this.$router.push("/");
        }
      }
    },
    async vote() {
      try {
        await this.$api.others.vote({
          id_bulleten: this.bulleten.id_bulleten,
          id_shailoo: this.bulleten.id_shailoo,
          vote: this.idKandidates,
        });
        this.kondidaty = [];
        this.m_v = 0;
        this.checkCount = 0;
        this.isAllHide = false;
        this.idKandidates = [];
        this.get_bulleten();
      } catch (error) {
        console.log(error);
      }
    },
    checkClick(b) {
      if (b) this.checkCount++;
      else this.checkCount--;
      if (this.checkCount >= this.m_v) this.isAllHide = true;
      else this.isAllHide = false;
    },
    voteGo() {
      this.idKandidates = this.kondidaty.map((i) => {
        return {
          id: i.id,
          golos: i.status ? true : false,
        };
      });
      console.log(this.kondidaty);
      console.log(this.idKandidates);
      this.vote();
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.get_bulleten();
    });
  },
  beforeDestroy() {
    this.stt = true;
  },
};
</script>
<style lang="scss">
.waiting {
  color: #1753af;
  font-size: 2em;
  padding: 30px 50px 50px;
  text-align: center;
}
</style>
