<template>
  <div
    id="app"
    class="app is-primary"
    :class="{ 'is-folded is-expand': ISFOLDED }"
  >
    <router-view />
    <Notification />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Notification from "@/components/others/Notification.vue";

export default {
  name: "App",
  components: {
    Notification,
  },
  data() {
    return {};
  },
  created() {
    const userLocal = JSON.parse(localStorage.getItem("user"));
    if (userLocal) {
      this.setUser(userLocal);
      this.$api.instance.defaults.headers.common["Authorization"] =
        "Bearer " + userLocal.token;
      this.$router.push({ name: "Bulletin" });
    } else this.$router.push("/");
  },

  computed: {
    ...mapGetters({
      ISFOLDED: "ISFOLDED",
      getUser: "user/getUser",
    }),
  },
  methods: {
    ...mapActions({
      setUser: "user/setUser",
    }),
  },
  mounted() {},
};
</script>

<style lang="scss"></style>
