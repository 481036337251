<template>
  <div class="main-content">
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2 class="header-title">Шайлоо</h2>
        </div>
        <div class="col-auto ml-auto">
          <button
            class="btn btn-primary"
            style="white-space: nowrap"
            @click="addModalShow"
          >
            Кошуу
          </button>
        </div>
      </div>
    </div>
    <!-- Content goes Here -->
    <div class="card">
      <div class="card-body">
        <template v-if="shailooList.length">
          <div class="row align-items-center">
            <div class="col-auto mr-auto mb-2" v-if="newSearchList.length">
              <PerPageLenth
                class="mr-3"
                :page="page"
                :perPage="perPage"
                :allDataLenth="newSearchList.length"
              />
              <PerPageCount :perPage="perPage" @perPageSet="perPageSet" />
            </div>
            <div class="col-auto ml-auto mb-2">
              <div class="row">
                <div class="col-auto">
                  <div class="input-affix">
                    <i class="prefix-icon anticon anticon-search"></i>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="searchText"
                      placeholder="Издөө"
                    />
                  </div>
                </div>
                <div class="col-auto">
                  <button
                    class="btn btn-sm btn-secondary mr-1"
                    @click="info_all_shailoo()"
                  >
                    Шайлоонун жыйынтыгы
                    <i class="anticon anticon-file-pdf"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr class="mt-0 mb-2" />
        </template>
        <template v-if="newSearchList.length">
          <table class="table table-responsive-md table-striped table-hover">
            <thead class="">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Аталашы</th>
                <th scope="col">Кызматы</th>
                <th scope="col">Орун</th>
                <th scope="col">Шайлоочулар</th>
                <th scope="col">Башталышы</th>
                <th scope="col">Аякташы</th>
                <th scope="col" class="text-center">
                  <i class="anticon anticon-unlock"></i>
                </th>
                <!-- <th scope="col" class="text-center"><i class="anticon anticon-eye"></i></th> -->
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in newDatalist" :key="item.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ item.name }}</td>
                <td>{{ item.doljnost }}</td>
                <td class="text-center">{{ item.m_v }}</td>
                <td class="text-center">{{ item.id_sostav_a_c }}</td>
                <td>{{ item.nachalo.toLocaleString() }}</td>
                <td>{{ item.konets.toLocaleString() }}</td>
                <td class="text-center">
                  <div class="checkbox">
                    <input
                      :id="'id' + index"
                      type="checkbox"
                      v-model="item.active_vote"
                      @change="actChange(item)"
                    />
                    <label class="tizme" :for="'id' + index"></label>
                  </div>
                </td>

                <td class="text-right">
                  <button
                    class="btn btn-icon btn-sm btn-primary btn-tone mr-1"
                    @click="result(item.id)"
                  >
                    <i class="anticon anticon-eye"></i>
                  </button>
                  <button
                    class="btn btn-icon btn-sm btn-secondary btn-tone mr-1"
                    :class="{ 'is-loading': false }"
                    @click="generateReport(item, index)"
                  >
                    <i class="anticon anticon-file-pdf"></i>
                    <!-- <i class="anticon anticon-loading"></i> -->
                  </button>
                  <button
                    class="btn btn-icon btn-sm btn-danger btn-tone"
                    @click="deleteModalShow(item)"
                  >
                    <i class="anticon anticon-delete"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
        </template>
        <div v-else class="noTdData">Маалымат жок</div>
        <Pagination
          :total="newSearchList.length"
          :perPage="perPage"
          @activePage="currentPage"
        />
      </div>
    </div>
    <Modal :isShow="isAddModalShow" @closeModal="addModalClose">
      <template v-slot:title> Шайлоону кошуу </template>
      <template>
        <div class="form-group">
          <label for="in1">Аталашы</label>
          <input
            type="text"
            class="form-control"
            id="in1"
            v-model="shailoo.name"
            required
          />
        </div>
        <div class="form-group">
          <label for="select_1_1">Шайлоочулар <span>*</span></label>
          <select
            class="form-control"
            id="select_1_1"
            v-model="id_ac"
            @change="acSelect"
            required
          >
            <option value="0" selected disabled>-- выбрать --</option>
            <template v-if="acList.length">
              <option v-for="item in acList" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </template>
          </select>
        </div>
        <div class="form-group">
          <label for="in2">Должность</label>
          <input
            type="text"
            class="form-control"
            id="in2"
            v-model="shailoo.doljnost"
            required
          />
        </div>
        <div class="form-group">
          <label for="in3">Множество выбора</label>
          <input
            type="text"
            class="form-control"
            id="in3"
            v-model="shailoo.m_v"
            required
          />
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="in_3">Башталышы</label>
              <input
                type="datetime-local"
                class="form-control text-right"
                id="in_3"
                v-model="shailoo.nachalo"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="in_4">Аякташы</label>
              <input
                type="datetime-local"
                class="form-control text-right"
                id="in_4"
                v-model="shailoo.konets"
                required
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-danger mr-3"
          @click="addModalClose"
        >
          Артка
        </button>
        <button type="button" class="btn btn-primary" @click="addPost">
          Сактоо
        </button>
      </template>
    </Modal>
    <Modal :isShow="isEditModalShow" @closeModal="editModalClose">
      <template v-slot:title> Шайлоону өзгөртүү </template>
      <template>
        <div class="form-group">
          <label for="in1">Аталашы</label>
          <input
            type="text"
            class="form-control"
            id="in1"
            v-model="shailoo.name"
            required
          />
        </div>
        <div class="form-group">
          <label for="in2">Описание</label>
          <textarea
            id="in2"
            class="form-control"
            rows="3"
            v-model="shailoo.description"
          ></textarea>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="in_3">Башталышы</label>
              <input
                type="datetime-local"
                class="form-control text-right"
                id="in_3"
                v-model="shailoo.tBegin"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="in_4">Аякташы</label>
              <input
                type="datetime-local"
                class="form-control text-right"
                id="in_4"
                v-model="shailoo.tEnd"
                required
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-danger mr-3"
          @click="editModalClose"
        >
          Артка
        </button>
        <button type="button" class="btn btn-primary">Сактоо</button>
      </template>
    </Modal>
    <Modal :isShow="isDeleteModalShow" @closeModal="deleteModalClose">
      <template v-slot:title> Шайлоону өчүрүү </template>

      <template>
        <h5>Чындап эле өчүрүүнү каалайсызбы?</h5>
        <p>{{ shailoo.name }}</p>
      </template>

      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-danger mr-3"
          @click="deleteModalClose"
        >
          Артка
        </button>
        <button type="button" class="btn btn-primary" @click="delPost">
          Тастыктоо
        </button>
      </template>
    </Modal>
    <template>
      <div>
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="01"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          @progress="onProgress($event)"
          @startPagination="startPagination()"
          @hasPaginated="hasPaginated()"
          ref="html2Pdf"
        >
          <section slot="pdf-content" class="pdfContainer">
            <!-- <div v-for="(item, index) in dataPDF" :key="index" class="hhh"> -->
            <section
              class="pdf-item"
              v-for="(item, index) in dataPDF"
              :key="index"
            >
              <h5 class="text-center mb-2">{{ item.name }}</h5>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" style="width: 2em">#</th>
                    <th scope="col">Талапкердин аты-жөнү</th>
                    <th scope="col">Шайлануучу кызмат</th>
                    <th scope="col">Добуш берүү</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ix, i) in item.tizme" :key="i">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ ix.fio }}</td>
                    <td>{{ item.doljnost }}</td>
                    <td class="text-center">
                      <span v-if="ix.golos" class="check">&#x2714;</span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <br v-for="i in br" :key="i" />

              <!-- <div class="html2pdf__page-break"/> -->
            </section>
          </section>
        </vue-html2pdf>
      </div>
    </template>
    <template>
      <div>
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="01"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="html2Pdf2"
        >
          <section slot="pdf-content">
            <div class="pdfContainer">
              <h4 class="text-center mb-3">Шайлоолордун жыйынтыгы</h4>

              <table class="table table-bordered">
                <tbody>
                  <tamplate
                    v-for="(item, index) in all_shailooPFD"
                    :key="index"
                  >
                    <tr>
                      <td colspan="2">
                        <div class="tema">{{ item.name }}</div>
                        <span class="text-center">({{ item.doljnost }})</span>
                      </td>
                      <td>Эскертүү</td>
                    </tr>
                    <tr v-for="(ix, i) in item.kondidaty" :key="i">
                      <td>{{ ix.fio }}</td>
                      <td style="width: 3rem">{{ ix.golos }}</td>
                      <td></td>
                    </tr>
                  </tamplate>
                </tbody>
              </table>
            </div>
          </section>
        </vue-html2pdf>
      </div>
    </template>
  </div>
</template>
<script>
import Pagination from "@/components/others/Pagination.vue";
import PerPageLenth from "@/components/others/PerPageLenth.vue";
import PerPageCount from "@/components/others/PerPageCount.vue";
import Modal from "@/components/others/Modal.vue";

import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "Vote",
  components: {
    Pagination,
    PerPageLenth,
    PerPageCount,
    Modal,
    VueHtml2pdf,
  },
  data() {
    return {
      isAddModalShow: false,
      isEditModalShow: false,
      isDeleteModalShow: false,

      page: 1,
      perPage: 10,
      searchText: "",

      acList: [],
      id_ac: null,

      shailoo: {
        id: null,
        name: "",
        doljnost: "",
        m_v: null,
        id_sostav_a_c: null,
        nachalo: null,
        konets: null,
      },
      shailooList: [],
      dataPDF: [{ name: "" }],
      all_shailooPFD: [
        {
          name: "",
          doljnost: "",
        },
      ],
      isLoadArr: [],
      isPdfLoad: false,
      arr: [1, 2, 3, 4, 5, 6, 7, 8, 8, 9],
      br: 2,
    };
  },
  computed: {
    newSearchList() {
      const regEx = new RegExp(this.searchText, "i");
      return this.shailooList.filter((x) => x.name.match(regEx));
    },
    newDatalist() {
      return this.newSearchList.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
  },
  methods: {
    startPagination() {
      this.isPdfLoad = true;
      console.log("sarted");
    },
    onProgress(a) {
      console.log(a);
    },
    hasPaginated() {
      console.log("open");
    },
    generateReport(item, index) {
      const b = parseInt(prompt("br", 2));
      this.br = b;

      this.print_bulleten(item).then((data) => {
        this.dataPDF = data;
        this.$refs.html2Pdf.generatePdf();
      });
      console.log(index);
    },
    currentPage(i) {
      this.page = i;
    },
    perPageSet(count) {
      this.perPage = count;
    },
    addModalShow() {
      this.get_ac();
      this.isAddModalShow = true;
    },
    addModalClose() {
      this.id_ac = null;
      this.shailoo = {};
      this.isAddModalShow = false;
    },
    editModalShow(item) {
      this.shailoo = { ...item };
      this.isEditModalShow = true;
    },
    editModalClose() {
      this.shailoo = {};
      this.isEditModalShow = false;
    },
    deleteModalShow(item) {
      this.shailoo = { ...item };
      this.isDeleteModalShow = true;
    },
    deleteModalClose() {
      this.shailoo = {};
      this.isDeleteModalShow = false;
    },
    async get_ac() {
      try {
        const data = (await this.$api.ac.get_ac()).data;
        this.acList = data;
      } catch (error) {
        if (error.response.status == 401) {
          this.$router.push("/");
        }
      }
    },
    async get_shailoo() {
      try {
        const data = (await this.$api.shailoo.get_shailoo()).data;
        this.shailooList = data.reverse();
      } catch (error) {
        if (error.response.status == 401) {
          this.$router.push("/");
        }
      }
    },
    async add_shailoo() {
      try {
        await this.$api.shailoo.add_shailoo(this.shailoo);
        this.$nt("Успешно сохранен", "success");
        this.get_shailoo();
      } catch (error) {
        if (error.response.status == 409) {
          console.log("409");
        }
        this.$nt("Ошибка сохранения", "dagner");
      }
    },
    async delete_shailoo() {
      try {
        await this.$api.shailoo.delete_shailoo(this.shailoo);
        this.$nt("Успешно удален", "success");
        this.get_shailoo();
      } catch (error) {
        if (error.response.status == 409) {
          console.log("409");
        }
        this.$nt("Ошибка удаления", "dagner");
      }
    },
    async vstart(item) {
      try {
        await this.$api.others.vstart({
          id_shailoo: item.id,
          act: item.active_vote,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async print_bulleten(item) {
      try {
        const data = (
          await this.$api.others.print_bulleten({
            id_shailoo: item.id,
          })
        ).data;
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async info_all_shailoo() {
      try {
        const data = (await this.$api.others.info_all_shailoo()).data;
        this.all_shailooPFD = data;
        this.$refs.html2Pdf2.generatePdf();
      } catch (error) {
        console.log(error);
      }
    },
    acSelect() {
      this.shailoo.id_sostav_a_c = this.id_ac;
    },
    addPost() {
      this.add_shailoo();
      this.addModalClose();
    },
    delPost() {
      this.delete_shailoo();
      this.deleteModalClose();
    },
    actChange(item) {
      this.vstart(item);
    },
    result(id) {
      this.$router.push({ name: "Result", params: { id: id } });
    },
  },

  mounted() {
    this.$nextTick(function () {
      this.get_shailoo();
    });
  },
};
</script>
<style lang="scss" scoped>
.checkbox input[type="checkbox"] + label.tizme:before {
  margin-right: 0;
  border: 2px solid #9dc2f5;
}
.checkbox input[type="checkbox"] + label {
  padding-left: 0px;
  margin-bottom: 0;
}
</style>
