<template>
  <div class="loginPage bg">
    <div class="container-fluid p-h-0 p-v-20 full-height d-flex">
      <div class="d-flex flex-column justify-content-between w-100">
        <div class="container d-flex justify-content-center h-100">
          <div class="row align-items-center w-100">
            <div class="col-md-8 col-lg-6 col-lg-5 m-h-auto">
              <div class="card shadow-lg">
                <div class="card-body">
                  <div
                    class="d-flex align-items-center justify-content-center m-b-30"
                  >
                    <img
                      class="logo"
                      alt=""
                      src="@/assets/images/logo/osu.svg"
                    />
                    <h2 class="isSignin m-b-0 mt-3">Авторизация</h2>
                  </div>
                  <form action="#" @submit.prevent="formSubmit">
                    <div class="form-group">
                      <label class="font-weight-semibold" for="userName"
                        >Логин</label
                      >
                      <div class="input-affix">
                        <i class="prefix-icon anticon anticon-user"></i>
                        <input
                          type="text"
                          class="form-control"
                          id="userName"
                          placeholder="Логин"
                          v-model="user.email"
                          required
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="font-weight-semibold" for="password"
                        >Сыр сөз:</label
                      >
                      <!-- <a class="float-right font-size-13 text-muted" href="">Forget Password?</a> -->
                      <div class="input-affix m-b-10">
                        <i class="prefix-icon anticon anticon-lock"></i>
                        <input
                          type="password"
                          class="form-control"
                          id="password"
                          placeholder="*****"
                          v-model="user.password"
                          required
                        />
                      </div>
                    </div>
                    <div class="mb-3 text-center text-danger" v-if="er401">
                      Логин же сыр сөз туура эмес
                    </div>
                    <div class="form-group text-center">
                      <button type="reset" class="btn btn-secondary mr-3">
                        Тазалоо
                      </button>
                      <button type="submit" class="btn btn-primary">
                        Кирүү
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      mode: "signIn",
      user: {
        f: "",
        i: "",
        o: "",
        email: "",
        password: "",
      },
      errors: [],
      er401: false,
    };
  },
  computed: {},
  methods: {
    ...mapActions("user", ["setUser"]),
    formSubmit() {
      this.signIn();
    },
    async signIn() {
      try {
        const data = (
          await this.$api.auth.signIn({
            email: this.user.email,
            password: this.user.password,
          })
        ).data;

        this.$api.instance.defaults.headers.common["Authorization"] =
          "Bearer " + data.token;

        localStorage.setItem("user", JSON.stringify(data));
        this.setUser(data);
        this.$router.push({ name: "Bulletin" });
        this.er401 = false;
      } catch (error) {
        console.log(error.response);
        if (
          error.response.data == "Unauthenticated login" ||
          error.response.status == 401
        ) {
          this.er401 = true;
        }
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
