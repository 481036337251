import Vue from "vue";
import VueRouter from "vue-router";

import Login from "../views/Login";
import Bulletin from "../views/Bulletin";
import Admin from "../views/Admin";

import Sostav from "../views/pages/Sostav";
import Vote from "../views/pages/Vote";
import Aspt from "../views/pages/Aspt";
import Users from "../views/pages/Users";
import Result from "../views/pages/Result";
import Page404 from "../views/pages/404";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/bulletin",
    name: "Bulletin",
    component: Bulletin,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    children: [
      {
        path: "/",
        name: "Users",
        component: Users,
      },
      {
        path: "/sostav",
        name: "Sostav",
        component: Sostav,
      },
      {
        path: "/vote",
        name: "Vote",
        component: Vote,
      },
      {
        path: "/aspt",
        name: "Aspt",
        component: Aspt,
      },
      {
        path: "/result",
        name: "Result",
        component: Result,
      },
    ],
  },
  {
    path: "*",
    name: "404",
    component: Page404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !localStorage.getItem("user"))
    next({ name: "Login" });
  else next();
});

export default router;
