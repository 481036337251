import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ApiPlugin from "./plugins/api";
import NotificationPlugin from "./plugins/notification";

Vue.config.productionTip = false;

Vue.use(ApiPlugin);
Vue.use(NotificationPlugin);

import "@/assets/css/app.min.css";
import "@/assets/scss/main.scss";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
