import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import notificate from "./modules/notificate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { user, notificate },
  state: {
    isFolded: false,
  },
  mutations: {
    SET_ISFOLDED: (state, data) => {
      state.isFolded = data;
    },
  },
  actions: {
    ISFOLDED_CHANGE({ commit }, data) {
      commit("SET_ISFOLDED", data);
    },
  },
  getters: {
    ISFOLDED(state) {
      return state.isFolded;
    },
  },
});
