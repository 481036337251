<template>
  <div>
    <div class="container-fluid">
      <div
        class="d-flex full-height p-v-20 flex-column justify-content-between"
      >
        <div class="d-none d-md-flex p-h-40">
          <img src="assets/images/logo/logo.png" alt="" />
        </div>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="p-v-30">
                <h1 class="font-weight-semibold display-1 text-primary lh-1-2">
                  404
                </h1>
                <h2 class="font-weight-light font-size-30">
                  Whoops! Looks like you got lost
                </h2>
                <p class="lead m-b-30">
                  We couldnt find what you were looking for.
                </p>
                <a href="" class="btn btn-primary btn-tone">Go Back</a>
              </div>
            </div>
            <div class="col-md-6 m-l-auto">
              <img
                class="img-fluid"
                src="assets/images/others/error-1.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="d-none d-md-flex p-h-40 justify-content-between">
          <span class="">© 2019 ThemeNate</span>
          <ul class="list-inline">
            <li class="list-inline-item">
              <a class="text-dark text-link" href="">Legal</a>
            </li>
            <li class="list-inline-item">
              <a class="text-dark text-link" href="">Privacy</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "404",
};
</script>
<style lang="scss" scoped></style>
