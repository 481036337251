export default function (instance) {
  return {
    act_sostav_ac(p) {
      return instance.post("act_sostav_ac", p);
    },
    vstart(p) {
      return instance.post("vstart", p);
    },
    get_bulleten() {
      return instance.post("get_bulleten");
    },
    vote(p) {
      return instance.post("vote", p);
    },
    info_shailoo(p) {
      return instance.post("info_shailoo", p);
    },
    print_bulleten(p) {
      return instance.post("print_bulleten", p);
    },
    info_all_shailoo() {
      return instance.post("info_all_shailoo", {});
    },
  };
}
