<template>
  <div>
    <div
      class="modal fade"
      ref="modal"
      @click="modalClick"
      :class="{ show: isShow }"
      :style="[
        isShow
          ? { display: 'block', paddingRight: '17px' }
          : { display: 'none' },
      ]"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5><slot name="title"></slot></h5>
            <button type="button" class="close" @click="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button
                type="button"
                class="btn btn-secondary"
                @click="closeModal"
              >
                Отмена
              </button>
              <button type="button" class="btn btn-primary">Принять</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    isShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      isModalShow: this.isShow,
      body: null,
    };
  },
  computed: {},
  mounted() {
    this.body = document.querySelector("body");
  },
  methods: {
    ModalShow() {
      if (this.isShow) {
        this.body.classList.add("modal-open");
        this.body.style = "padding-right: 17px";
      } else {
        this.body.classList.remove("modal-open");
        this.body.style = "";
      }
    },
    modalClick(e) {
      if (e.target == this.$refs.modal) this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped></style>
