export default function (instance) {
  return {
    get_shailoo() {
      return instance.post("get_shailoo", {});
    },
    add_shailoo(p) {
      return instance.post("add_shailoo", p);
    },
    delete_shailoo(p) {
      return instance.post("delete_shailoo", p);
    },
  };
}
