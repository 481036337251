export default {
  namespaced: true,
  state: {
    messages: [],
  },
  getters: {
    getMSG(state) {
      return state.messages;
    },
  },
  mutations: {
    SET_MSG(state, payload) {
      state.messages.push(payload);
    },
    DELETE_MSG(state) {
      if (state.messages.length) {
        setTimeout(() => {
          state.messages.splice(0, 1);
        }, 3000);
      }
    },
  },
  actions: {
    setMSG({ commit }, payload) {
      commit("SET_MSG", payload);
    },
    deleteMSG({ commit }) {
      commit("DELETE_MSG");
    },
  },
};
