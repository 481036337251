<template>
  <div class="main-content">
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2 class="header-title">Колдонуучулар</h2>
        </div>
        <div class="col-auto ml-auto">
          <button
            class="btn btn-primary"
            style="white-space: nowrap"
            @click="addModalShow"
          >
            Кошуу
          </button>
        </div>
      </div>
    </div>
    <!-- Content goes Here -->
    <div class="card">
      <div class="card-body">
        <template v-if="newSearchList.length">
          <div class="row align-items-center" v-if="newSearchList.length">
            <div class="col-auto mr-auto mb-2">
              <PerPageLenth
                class="mr-3"
                :page="page"
                :perPage="perPage"
                :allDataLenth="newSearchList.length"
              />
              <PerPageCount :perPage="perPage" @perPageSet="perPageSet" />
            </div>
          </div>
          <hr class="mt-0 mb-2" />
        </template>
        <template v-if="newDatalist.length">
          <table
            class="table table-sm table-responsive-md table-striped table-hover"
          >
            <thead class="">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Фамилиясы</th>
                <th scope="col">Аты</th>
                <th scope="col">Атасынын аты</th>
                <th scope="col">Email</th>
                <th scope="col">Катталган</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in newDatalist" :key="item.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ item.f }}</td>
                <td>{{ item.i }}</td>
                <td>{{ item.o }}</td>
                <td>{{ item.email }}</td>
                <td>{{ new Date(item.created_at).toLocaleString() }}</td>

                <td class="text-right">
                  <!-- <button class="btn btn-primary btn-xs mr-1">btn-xs</button> -->
                  <button
                    class="btn btn-icon btn-sm btn-success btn-tone mr-1 mb-1 mb-lg-0"
                    @click="editModalShow(item)"
                  >
                    <i class="anticon anticon-edit"></i>
                  </button>
                  <button
                    class="btn btn-icon btn-sm btn-danger btn-tone"
                    @click="deleteModalShow(item)"
                  >
                    <i class="anticon anticon-delete"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
        </template>
        <div v-else class="noTdData">Маалымат жок</div>

        <Pagination
          :total="newSearchList.length"
          :perPage="perPage"
          @activePage="currentPage"
        />
      </div>
    </div>
    <Modal :isShow="isAddModalShow" @closeModal="addModalClose">
      <template v-slot:title>Регистрация</template>
      <template>
        <form action="#">
          <div class="form-group">
            <label class="font-weight-semibold" for="f">Фамилия:</label>
            <div class="input-affix">
              <i class="prefix-icon anticon anticon-user"></i>
              <input
                type="text"
                class="form-control form-control-sm"
                id="f"
                placeholder="Эшматов"
                v-model.trim="user.f"
                :class="[
                  { 'is-invalid': $v.user.f.$error },
                  { 'is-valid': $v.user.f.required },
                ]"
                @blur="$v.user.f.$touch()"
              />
            </div>
            <div
              class="invalid-feedback"
              v-if="!$v.user.f.required && $v.user.f.$dirty"
            >
              Это поле обязательно
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-semibold" for="i">Имя:</label>
            <div class="input-affix">
              <i class="prefix-icon anticon anticon-user"></i>
              <input
                type="text"
                class="form-control form-control-sm"
                id="i"
                placeholder="Ташмат"
                v-model.trim="user.i"
                :class="[
                  { 'is-invalid': $v.user.i.$error },
                  { 'is-valid': $v.user.i.required },
                ]"
                @blur="$v.user.i.$touch()"
              />
            </div>
            <div
              class="invalid-feedback"
              v-if="!$v.user.i.required && $v.user.i.$dirty"
            >
              Это поле обязательно
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-semibold" for="o">Отчество:</label>
            <div class="input-affix">
              <i class="prefix-icon anticon anticon-user"></i>
              <input
                type="text"
                class="form-control form-control-sm"
                id="o"
                placeholder="Полотович"
                v-model.trim="user.o"
              />
            </div>
          </div>

          <div class="form-group">
            <label class="font-weight-semibold" for="email">Email:</label>
            <div class="input-affix">
              <i class="prefix-icon anticon anticon-mail"></i>
              <input
                type="email"
                class="form-control form-control-sm"
                id="email"
                placeholder="Email"
                v-model.trim="user.email"
                :class="[
                  { 'is-invalid': $v.user.email.$error },
                  { 'is-valid': $v.user.email.required && $v.user.email.email },
                ]"
                @blur="$v.user.email.$touch()"
              />
            </div>
            <div
              class="invalid-feedback"
              v-if="!$v.user.email.required && $v.user.email.$dirty"
            >
              Поле электронной почты обязательно
            </div>
            <div
              class="invalid-feedback"
              v-if="!$v.user.email.email && $v.user.email.$dirty"
            >
              Это поле должно быть адресом электронной почты
            </div>
          </div>

          <div class="form-group">
            <label class="font-weight-semibold" for="password">Пароль:</label>
            <div class="input-affix m-b-10">
              <i class="prefix-icon anticon anticon-lock"></i>
              <input
                type="password"
                class="form-control form-control-sm"
                id="password"
                placeholder="*****"
                v-model.trim="user.password"
                :class="[
                  { 'is-invalid': $v.user.password.$error },
                  {
                    'is-valid':
                      $v.user.password.required && $v.user.password.minLength,
                  },
                ]"
                @blur="$v.user.password.$touch()"
              />
            </div>
            <div
              class="invalid-feedback"
              v-if="!$v.user.password.required && $v.user.password.$dirty"
            >
              Поле пароля почты обязательно
            </div>
            <div
              class="invalid-feedback"
              v-if="!$v.user.password.minLength && $v.user.password.$dirty"
            >
              Это поле должно быть минимум 6 символов
            </div>
          </div>

          <div class="form-group mb-0">
            <label class="font-weight-semibold" for="confirm"
              >Потвердит пароль:</label
            >
            <div class="input-affix m-b-10">
              <i class="prefix-icon anticon anticon-lock"></i>
              <input
                type="password"
                class="form-control form-control-sm"
                id="confirm"
                placeholder="*****"
                v-model.trim="user.confirm"
                :class="[
                  { 'is-invalid': $v.user.confirm.$error },
                  {
                    'is-valid':
                      $v.user.confirm.required && $v.user.confirm.sameAs,
                  },
                ]"
                @blur="$v.user.confirm.$touch()"
              />
            </div>
            <div
              class="invalid-feedback"
              v-if="!$v.user.confirm.required && $v.user.password.$dirty"
            >
              Поле пароля почты обязательно
            </div>
            <div
              class="invalid-feedback"
              v-if="$v.user.confirm.$error && $v.user.confirm.$dirty"
            >
              Пароль должен совпадать
            </div>
          </div>
        </form>
      </template>

      <template v-slot:footer>
        <div class="d-flex justify-content-center">
          <button
            type="reset"
            class="btn btn-secondary mr-3"
            @click="addModalClose"
          >
            Артка
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            @click.prevent="formSubmit"
          >
            Тастыктоо
          </button>
        </div>
      </template>
    </Modal>
    <Modal :isShow="isEditModalShow" @closeModal="editModalClose">
      <template v-slot:title> Колдонуучуну өзгөртүү </template>
      <template>
        <div class="form-group">
          <label for="in1">Фамилиясы</label>
          <input
            type="text"
            class="form-control"
            id="in1"
            v-model="user.f"
            required
          />
        </div>
        <div class="form-group">
          <label for="in2">Аты</label>
          <input
            type="text"
            class="form-control"
            id="in2"
            v-model="user.i"
            required
          />
        </div>
        <div class="form-group">
          <label for="in3">Атасынын аты</label>
          <input
            type="text"
            class="form-control"
            id="in3"
            v-model="user.o"
            required
          />
        </div>
        <div class="form-group">
          <label for="in4">Email</label>
          <input
            type="text"
            class="form-control"
            id="in4"
            v-model="user.email"
            required
          />
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-danger mr-3"
          @click="editModalClose"
        >
          Артка
        </button>
        <button type="button" class="btn btn-primary">Сактоо</button>
      </template>
    </Modal>
    <Modal :isShow="isDeleteModalShow" @closeModal="deleteModalClose">
      <template v-slot:title> Колдонуучуну өчүрүү </template>

      <template>
        <h5>Чындап эле өчүрүүнү каалайсызбы?</h5>
        <p>{{ user.f + " " + user.i + " " + user.o }}</p>
      </template>

      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-danger mr-3"
          @click="deleteModalClose"
        >
          Артка
        </button>
        <button type="button" class="btn btn-primary">Тастыктоо</button>
      </template>
    </Modal>
  </div>
</template>
<script>
import Pagination from "@/components/others/Pagination.vue";
import PerPageLenth from "@/components/others/PerPageLenth.vue";
import PerPageCount from "@/components/others/PerPageCount.vue";
import Modal from "@/components/others/Modal.vue";

import { validationMixin } from "vuelidate";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";

export default {
  name: "Users",
  mixins: [validationMixin],
  components: {
    Pagination,
    PerPageLenth,
    PerPageCount,
    Modal,
  },
  validations: {
    user: {
      f: { required },
      i: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) },
      confirm: { required, sameAs: sameAs("password") },
    },
  },

  data() {
    return {
      isAddModalShow: false,
      isEditModalShow: false,
      isDeleteModalShow: false,

      page: 1,
      perPage: 10,
      searchText: "",
      user: {
        id: null,
        f: "",
        i: "",
        o: "",
        email: "",
        password: "",
        confirm: "",
        created_at: null,
      },

      userlist: [],
    };
  },
  computed: {
    newSearchList() {
      const regEx = new RegExp(this.searchText, "i");
      return this.userlist.filter((x) => x.f.match(regEx));
    },
    newDatalist() {
      return this.newSearchList.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
  },
  methods: {
    currentPage(i) {
      this.page = i;
    },
    perPageSet(count) {
      this.perPage = count;
    },
    addModalShow() {
      this.isAddModalShow = true;
    },
    addModalClose() {
      this.user = {};
      this.isAddModalShow = false;
    },
    editModalShow(item) {
      this.user = { ...item };
      this.isEditModalShow = true;
    },
    editModalClose() {
      this.user = {};
      this.isEditModalShow = false;
    },
    deleteModalShow(item) {
      this.user = { ...item };
      this.isDeleteModalShow = true;
    },
    deleteModalClose() {
      this.user = {};
      this.isDeleteModalShow = false;
    },
    formSubmit() {
      if (this.$v.$invalid) {
        this.$v.user.$touch();
      } else {
        this.add_users();
      }
    },
    async get_users() {
      try {
        const data = (
          await this.$api.users.get_users({
            // page: this.page,
            // perPage: this.perPage,
          })
        ).data;
        this.userlist = data;
      } catch (error) {
        if (error.response.status == 401) {
          this.$router.push("/");
        }
      }
    },

    async add_users() {
      try {
        await this.$api.users.add_users(this.user);
        this.addModalClose();
        this.get_users();
      } catch (error) {
        if (error.response.status == 409) {
          console.log("409");
        }
      }
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.get_users();
    });
  },
};
</script>
<style lang="scss" scoped></style>
