<template>
  <span v-if="allDataLenth > 0"> {{ pageNumber }} / {{ allDataLenth }} </span>
</template>

<script>
export default {
  name: "PerPageLenth",
  props: {
    page: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    perPage: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    allDataLenth: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    pageNumber() {
      let n = Math.ceil(this.allDataLenth / this.perPage);
      if (n == this.page) {
        return this.allDataLenth % this.perPage;
      } else {
        return this.perPage;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
