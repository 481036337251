<template>
  <span v-if="perPage > 0">
    <span class="mr-2">Көрсөтүү:</span>
    <select
      class="custom-select custom-select-sm"
      v-model="pp"
      @change="perPageChange"
    >
      <option v-for="(item, index) in perPages" :value="item" :key="index">
        {{ item }}
      </option>
    </select>
  </span>
</template>

<script>
export default {
  name: "PerPageCount",
  props: {
    perPage: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  data() {
    return {
      pp: this.perPage,
      perPages: [5, 10, 15, 25, 50, 100],
    };
  },
  computed: {},
  methods: {
    perPageChange() {
      this.$emit("perPageSet", this.pp);
    },
  },
};
</script>

<style lang="scss" scoped>
select {
  width: 5em;
}
</style>
