<template>
  <div>
    <!-- <Search class="mr-2 mb-3" /> -->
    <div class="row align-items-end">
      <div class="col-auto flex-grow-1">
        <div class="form-group">
          <label for="select_1_1">Издөө</label>
          <div class="input-affix">
            <i class="prefix-icon anticon anticon-search"></i>
            <input
              type="text"
              class="form-control"
              v-model="searchText"
              placeholder="Поиск"
            />
          </div>
        </div>
      </div>

      <div class="col-auto">
        <button
          class="btn btn-primary mb-4 ml-auto d-block"
          style="white-space: nowrap"
          @click="addModalShow"
        >
          Кошуу
        </button>
      </div>
    </div>
    <hr class="mt-0 mb-2" />
    <template v-if="newSearchList.length">
      <div class="row align-items-center" v-if="newSearchList.length">
        <div class="col-auto mr-auto mb-2">
          <PerPageLenth
            class="mr-3"
            :page="page"
            :perPage="perPage"
            :allDataLenth="newSearchList.length"
          />
          <PerPageCount :perPage="perPage" @perPageSet="perPageSet" />
        </div>
      </div>
      <hr class="mt-0 mb-2" />
    </template>
    <template v-if="newSearchList.length">
      <table
        class="table table-sm table-responsive-md table-striped table-hover"
      >
        <thead class="">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Аталышы</th>
            <th scope="col">Катталган</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in newDatalist" :key="item.id">
            <th scope="row">{{ index + perPage * (page - 1) + 1 }}</th>
            <td>{{ item.name }}</td>
            <td>{{ new Date(item.created_at).toLocaleString() }}</td>

            <td class="text-right">
              <!-- <button class="btn btn-primary btn-xs mr-1">btn-xs</button> -->
              <button
                class="btn btn-icon btn-sm btn-success btn-tone mr-1 mb-1 mb-lg-0"
                @click="editModalShow(item)"
              >
                <i class="anticon anticon-edit"></i>
              </button>
              <button
                class="btn btn-icon btn-sm btn-danger btn-tone"
                @click="deleteModalShow(item)"
              >
                <i class="anticon anticon-delete"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
    </template>
    <div v-else class="noTdData">Маалымат жок</div>

    <Pagination
      :total="newSearchList.length"
      :perPage="perPage"
      @activePage="currentPage"
    />
    <Modal :isShow="isAddModalShow" @closeModal="addModalClose">
      <template v-slot:title> Кошуу </template>
      <template>
        <div class="form-group">
          <label for="kg_l">Аталышы</label>
          <input
            type="text"
            class="form-control"
            id="kg_l"
            v-model="ac.name"
            required
          />
        </div>
      </template>

      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-danger mr-3"
          @click="addModalClose"
        >
          Артка
        </button>
        <button type="button" class="btn btn-primary" @click="addPost">
          Сактоо
        </button>
      </template>
    </Modal>
    <Modal :isShow="isEditModalShow" @closeModal="editModalClose">
      <template v-slot:title> Өзгөртүү </template>
      <template v-if="newDatalist.length">
        <div class="form-group">
          <label for="kg_l">Аталышы</label>
          <input
            type="text"
            class="form-control"
            id="kg_l"
            v-model="ac.name"
            required
          />
        </div>
      </template>

      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-danger mr-3"
          @click="editModalClose"
        >
          Артка
        </button>
        <button type="button" class="btn btn-primary" @click="editPost">
          Сактоо
        </button>
      </template>
    </Modal>
    <Modal :isShow="isDeleteModalShow" @closeModal="deleteModalClose">
      <template v-slot:title> Өчүрүү </template>

      <template>
        <h5>Чындап эле өчүрүүнү каалайсызбы?</h5>
        <p>{{ ac.name }}</p>
      </template>

      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-danger mr-3"
          @click="deleteModalClose"
        >
          Артка
        </button>
        <button type="button" class="btn btn-primary" @click.prevent="delPost">
          Тастыктоо
        </button>
      </template>
    </Modal>
  </div>
</template>
<script>
import Pagination from "@/components/others/Pagination.vue";
import PerPageLenth from "@/components/others/PerPageLenth.vue";
import PerPageCount from "@/components/others/PerPageCount.vue";
import Modal from "@/components/others/Modal.vue";

export default {
  name: "SName",
  components: {
    Pagination,
    PerPageLenth,
    PerPageCount,
    Modal,
  },
  data() {
    return {
      isAddModalShow: false,
      isEditModalShow: false,
      isDeleteModalShow: false,

      page: 1,
      perPage: 10,
      searchText: "",

      SNameList: [],
      ac: {
        name: "",
        created_at: null,
      },
    };
  },
  computed: {
    newSearchList() {
      const regEx = new RegExp(this.searchText, "i");
      return this.SNameList.filter((x) => x.name.match(regEx));
    },
    newDatalist() {
      return this.newSearchList.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
  },
  methods: {
    currentPage(i) {
      this.page = i;
    },
    perPageSet(count) {
      this.perPage = count;
    },

    addModalShow() {
      this.isAddModalShow = true;
    },
    addModalClose() {
      this.ac = {};
      this.isAddModalShow = false;
    },
    editModalShow(item) {
      this.ac = { ...item };
      this.isEditModalShow = true;
    },
    editModalClose() {
      this.ac = {};
      this.isEditModalShow = false;
    },
    deleteModalShow(item) {
      this.ac = { ...item };
      this.isDeleteModalShow = true;
    },
    deleteModalClose() {
      this.ac = {};
      this.isDeleteModalShow = false;
    },
    async get_ac() {
      try {
        const data = (
          await this.$api.ac.get_ac({
            // page: this.page,
            // perPage: this.perPage,
          })
        ).data;
        this.SNameList = data;
      } catch (error) {
        if (error.response.status == 401) {
          this.$router.push("/");
        }
      }
    },
    async add_ac() {
      try {
        await this.$api.ac.add_ac(this.ac);
        this.$nt("Успешно сохранен", "success");
        this.get_ac();
      } catch (error) {
        if (error.response.status == 409) {
          console.log("409");
        }
        this.$nt("Ошибка сохранения", "dagner");
      }
    },
    async update_ac() {
      try {
        await this.$api.ac.update_ac(this.ac);
        this.$nt("Успешно изменен", "success");
        this.get_ac();
      } catch (error) {
        if (error.response.status == 409) {
          console.log("409");
        }
        this.$nt("Ошибка изменения", "dagner");
      }
    },
    async delete_ac() {
      try {
        await this.$api.ac.delete_ac(this.ac);
        this.$nt("Успешно удален", "success");
        this.get_ac();
      } catch (error) {
        if (error.response.status == 409) {
          console.log("409");
        }
        this.$nt("Ошибка удаления", "dagner");
      }
    },
    addPost() {
      this.add_ac();
      this.addModalClose();
    },
    editPost() {
      this.update_ac();
      this.editModalClose();
    },
    delPost() {
      this.delete_ac();
      this.deleteModalClose();
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.get_ac();
    });
  },
};
</script>
<style lang="scss" scoped>
td:last-child {
  word-wrap: none;
}
</style>
