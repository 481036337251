<template>
  <div class="mainPage">
    <AdminHeader />
    <AdminSide />

    <div class="page-container">
      <!-- <transition name="route"> -->
        <router-view />
      <!-- </transition> -->

      <!-- Footer START -->
      <footer class="footer">
        <div class="footer-content">
          <p class="m-b-0 mx-auto">
            Информация© 2021 ОшГУ | Development © 2022 OshSU - IT Academy
          </p>
        </div>
      </footer>
      <!-- Footer END -->
    </div>
  </div>
</template>
<script>
import AdminHeader from "../components/AdminHeader.vue";
import AdminSide from "../components/AdminSide.vue";

export default {
  name: "Admin",
  components: {
    AdminHeader,
    AdminSide,
  },
  data() {
    return {
      message: [],
    };
  },
};
</script>
<style lang="scss"></style>
