export default function (instance) {
  return {
    get_kand(p) {
      return instance.post("get_kand", p);
    },
    add_kand(p) {
      return instance.post("add_kand", p);
    },
    delete_kand(p) {
      return instance.post("delete_kand", p);
    },
  };
}
